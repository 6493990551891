<template>
  <div>
    <div class="banner">
      <div class="position-relative" style="z-index:1">
        <div class="subtitle7 success">플랫폼파이 스킨</div>
        <div class="mo-section-title-bold main margin-top-8">솔루션 기반의 플랫폼 서비스 스킨.<br>
          그대로 적용할 수도 있고 <br>부분 수정개발도 가능해요
        </div>
        <div class="body4 sub2 margin-top-20">스킨 구매 후 원하는 부분을 부분만 수정해서 <br>
          내 비즈니스에 맞는 서비스로 <br>
          만들 수 있기 때문에 합리적이예요.
        </div>
      </div>
    </div>

    <div class="h8 main" style="padding:24px 16px 12px 16px">플랫폼파이 스킨</div>

    <div v-for="(card, idx) in skins" :key="`card-${card.id}`">
      <div class="gap" v-if="idx>0"></div>
      <card-product :card="card" style="padding:16px"></card-product>
    </div>
    <div style="padding:16px" v-if="page_num < total_page">
      <button class="button is-gray button-more" @click="clickMore">더보기</button>
    </div>
  </div>
</template>

<script>
  import CardProduct from "../../../component/CardProduct";

  export default {
    name: "SkinsMobile",
    components: {CardProduct},
    created() {
      this.getData();
    },
    data() {
      return {
        page_num: 1,
        total_page: 1,
        skins: []
      }
    },
    methods: {
      clickMore() {
        this.page_num++;
        this.getData();
      },
      getData() {
        let params = {
          category: 60,
          fields: 'id,name,simple_desc,price,img,partner.img,partner.name,category1,category2,preview_url,visit,rate,theme_version',
          page_num: this.page_num,
          page_length: 10,
          ordering: 'priority'
        };
        this.$axios.get('user/0/mapping/product', {params: params}).then(res => {
          this.skins = res.data.data;
          this.total_page = res.data.total_page;
        });
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .banner
    background-color #EDFBF4
    padding 40px 0
    overflow hidden
    position relative
    text-align center

  .grid-banner
    position relative
    display grid
    gap 60px
    grid-template-columns 1fr 502px

  .circle
    position absolute
    width 356px
    height 356px
    left calc(50% - 80px)
    top calc(50% - 38px)
    background #17ff90
    opacity 0.5
    filter blur(200px)
    z-index 0
</style>
